/* stylelint-disable declaration-empty-line-before */
@import url(../styles/base.css);

html,
body {
    background-color: hsl(0deg, 0%, 98%);
}

$breakpoint-small: 500px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;

/* Cover */

@keyframes animate-out {
    0% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}

#cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
    display: flex;
    background-color: var(--rc-blue);
    justify-content: center;
    align-items: center;
    h1 {
        color: white;
        font-size: 128px;
        font-weight: 140;
        letter-spacing: -6px;
    }

    &.removing {
        animation: animate-out 1s cubic-bezier(0.64, 0, 0.78, 0);
        opacity: 0;
    }
}

/* Background */

#background-container {
    /* animation: background-animation 10s infinite; */
}

@keyframes background-animation {
    0% {
        background-color: var(--rc-blue);
    }

    16.6666666667% {
        background-color: var(--rc-pink);
    }

    33.3333333333% {
        background-color: var(--rc-orange);
    }

    50% {
        background-color: var(--rc-green);
    }

    66.6666666667% {
        background-color: var(--rc-violet);
    }

    83.3333333333% {
        background-color: var(--rc-red);
    }

    100% {
        background-color: var(--rc-blue);
    }
}

body.debug {
    background-color: hsl(0deg, 0%, 50%);
    border-right: 1px solid black;
}
.debug #background {
    background: url(../../assets/ResponsiveGuide.png) repeat-y;
    background-color: hsl(0deg, 0%, 98%);
}

#background {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    padding-top: 12px;

    width: 375px;
    left: calc((100vw - 375px) * 0.5);
    @media (min-width: $breakpoint-small) {
        width: $breakpoint-small;
        left: calc((100vw - $breakpoint-small) * 0.5);
    }
    @media (min-width: $breakpoint-medium) {
        width: $breakpoint-medium;
        left: calc((100vw - $breakpoint-medium) * 0.5);
    }
    @media (min-width: $breakpoint-large) {
        width: $breakpoint-large;
        left: calc((100vw - $breakpoint-large) * 0.5);
    }
    @media (min-width: $breakpoint-xlarge) {
        width: $breakpoint-xlarge;
        left: calc((100vw - $breakpoint-xlarge) * 0.5);
    }
}

/* Typography */
body {
    h1,
    h2,
    h3,
    h4,
    h5,
    div,
    li,
    p {
        color: #0075ff;
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        @media (min-width: $breakpoint-small) {
            font-size: 14px;
            line-height: 20px;
        }
        font-variation-settings: "slnt" 0;
        padding: 0;
        margin: 0;
        letter-spacing: 0.005rem;
        text-underline-offset: 3px;
    }

    a,
    a:visited {
        // text-decoration-color: var(--rc-pink);
    }
}

h3 {
    text-decoration: underline;
}

a:visited {
    color: #0075FF;
}

.weak {
    opacity: 0.6;
}

li {
    list-style: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

/* Header & Nav */

header,
nav {
    h1,
    h2,
    li,
    a {
        font-size: 14px;
        line-height: 18px;
        font-weight: 350;
        @media (min-width: $breakpoint-small) {
            font-size: 16px;
            line-height: 20px;
            font-weight: 300;
        }
        margin: 0;
        padding: 0;
        color: var(--rc-blue);

        // span {
        //     background-color: var(--rc-blue);
        //     color: white;
        //     border: 1px solid var(--rc-blue);
        //     border-radius: 2px;
        //     padding-left: 3px;
        //     padding-right: 3px;
        // }

        @media (min-width: $breakpoint-small) {
            br.break-small {
                display: none;
            }
        }
    }

    ul {
        margin: 0;
        line-height: 20px;
    }
}

header {
    margin: 14px 0 0 12px;
    @media (min-width: $breakpoint-small) {
        margin: 21px 0 0 22px;
    }
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
}

nav {
    margin: 8px 12px;
    @media (min-width: $breakpoint-small) {
        margin: 18px 22px;
    }
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    text-align: right;

    .menu {

    }

    ul.horizontal {
        line-height: 16px;
    }

    ul.horizontal li {
        padding-right: 0;
        padding-left: 6px;
    }
}


/* Section */

section {
    margin-top: 87px;
}

section#contact {
    // display: flex;
    // justify-content: end;
    // margin-top: 0px;

    // h3 {
    //     display: block;
    //     text-align: right;
    //     width: 150px;
    // }

    // ul {
    //     width: 180px;
    // }

    ul {
        margin: 0;
    }
}

/* Infoboxes */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#connectors {
    animation: fadeIn 3s forwards;
    opacity: 0;
}

.info-box-container,
.solo-box {
    height: 450px;
    pointer-events: none;
}

.debug {
    .info-box,
    .solo-box {
        border-top: 1px dotted hsl(0, 0%, 52%);
    }
}

.info-box,
.solo-box {
    animation: fadeIn 3s forwards;
    opacity: 0;
    position: relative;

    img,
    video {
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 30;
        pointer-events: auto;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        &.no-shadow {
            box-shadow: none;
        }
    }

    .info-pointer {
        position: absolute;
        top: 105px;
        left: 175px;
    }

    .box-safari-fix {
        -webkit-backdrop-filter: blur(20px);
        border-radius: 7px;
        box-sizing: border-box;
    }

    .box {
        box-sizing: border-box;

        border: 1px solid #0075ff;
        @media (min-width: $breakpoint-small) {
            border: 2px solid #0075ff;
        }
        border-radius: 6px;
        @media (min-width: $breakpoint-small) {
            border-radius: 8px;
        }
        position: absolute;
        top: 0;
        left: 0;
        z-index: 40;
        pointer-events: auto;
        background-color: hsla(0deg, 0%, 98%, 0.85);
        backdrop-filter: blur(20px);

        h3,
        h4,
        p {
            text-decoration: none;
        }

        .box-text-padded {
            border-top-left-radius: 5px;
            padding: 2px 7px 1px 6px;
            @media (min-width: $breakpoint-small) {
                border-top-left-radius: 0;
                padding: 3px 12px 3px 7px;
            }
        }

        .box-text {
            padding: 5px 6px 7px 6px;
            @media (min-width: $breakpoint-small) {
                padding: 5px 17px 9px 8px;
            }
        }

        .box-text-content {
            max-width: 600px;
        }

        .box-text-expand {
            a {
                text-decoration: none;
                color: var(--rc-pink);
            }
        }

        .box-header {
            border-bottom: 1px solid #0075ff;
            @media (min-width: $breakpoint-small) {
                border-bottom: 2px solid #0075ff;
            }
            display: flex;
        }

        .box-header div:first-child {
            background-color: #0075ff;
            color: white;

            h3 {
                color: white;
                font-weight: 400;
            }
        }
    }

    .pullout {
        position: absolute;
        top: -14px;
        left: 46px;
        width: 2px;
        height: 12px;


        .pullout-content {
            position: relative;
            top: -22px;
            left: -37px;
            height: 22px;
            @media (min-width: $breakpoint-small) {
                height: 25px;
            }
    
            border-radius: 100px;
            display: inline-block;
            overflow: visible;
            white-space: nowrap;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 2px;
            font-weight: 400;
        }
    }
}

section.text {
    margin: 22px 22px 40px 22px;
    max-width: 580px;

    h3,
    p {
        margin-bottom: 16px;
    }
}

.section-header {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    z-index: 100;

    h1 {
        font-size: 120px;
        font-weight: 100;
        letter-spacing: -8px;
        line-height: 120px;
        text-align: center;
        display: inline-block;
        border-radius: 8px;
        color: var(--rc-pink);
    }
}

.border-blue {
    border: 1px solid var(--rc-blue);
}
