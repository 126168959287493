@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizelegibility;
  min-height: 100vh;
  line-height: 1.5;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font: inherit;
}

:root {
  --rc-blue: #0075ff;
  --rc-blue-dark: #2c5c93;
  --rc-blue-light: #0075ff66;
  --rc-pink: #ff00b8;
  --rc-orange: #ffa300;
  --rc-peach: #ffc48d;
  --rc-green: #96ffc6;
  --rc-violet: #00c8ff;
  --rc-red: #ff1500;
  --rc-purple: #a04af3;
}

.blue {
  color: var(--rc-blue);
}

.bg-blue {
  background-color: var(--rc-blue);
}

.blue-dark {
  color: var(--rc-blue-dark);
}

.bg-blue-dark {
  background-color: var(--rc-blue-dark);
}

.blue-light {
  color: var(--rc-blue-light);
}

.bg-blue-light {
  background-color: var(--rc-blue-light);
}

.pink {
  color: var(--rc-pink);
}

.bg-pink {
  background-color: var(--rc-pink);
}

.orange {
  color: var(--rc-orange);
}

.bg-orange {
  background-color: var(--rc-orange);
}

.peach {
  color: var(--rc-peach);
}

.bg-peach {
  background-color: var(--rc-peach);
}

.green {
  color: var(--rc-green);
}

.bg-green {
  background-color: var(--rc-green);
}

.violet {
  color: var(--rc-violet);
}

.bg-violet {
  background-color: var(--rc-violet);
}

.red {
  color: var(--rc-red);
}

.bg-red {
  background-color: var(--rc-red);
}

.purple {
  color: var(--rc-purple);
}

.bg-purple {
  background-color: var(--rc-purple);
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
  color: var(--rc-blue);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 320;
  line-height: 24px;
}

h1, h2, h3, h4, h5, h6, p, th, li, label, .p-like {
  color: var(--rc-blue);
  margin: 6px 0;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 240;
  line-height: 24px;
}

a, a:active, a:visited, strong, code {
  color: var(--rc-blue);
  font-style: normal;
}

code, pre {
  margin: 6px 0;
  padding: 0;
  font-family: Space Mono, monospace;
  font-style: normal;
  font-weight: 400;
}

h1 {
  color: var(--rc-pink);
  letter-spacing: -.2px;
  margin-bottom: 30px;
  font-size: 42px;
  line-height: 42px;
}

h2 {
  letter-spacing: -.2px;
  margin: 0 0 18px;
  font-size: 32px;
  line-height: 36px;
}

a {
  text-underline-offset: 3px;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--rc-pink);
  text-decoration-color: var(--rc-pink);
}

li {
  margin: 0;
  padding: 0;
}

ul.horizontal li {
  padding-right: 6px;
  display: inline-block;
}

strong {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--rc-pink);
  text-decoration-color: var(--rc-pink);
  text-underline-offset: 2px;
  font-weight: 400;
}

input, button, label {
  box-sizing: border-box;
  border: 0;
  height: 28px;
  margin: 0;
  padding: 0;
}

textarea {
  height: 48px;
  margin: 0;
  padding: 0;
}

.pad {
  margin: 24px;
}

.debug-grid {
  background-image: url("GridBackground.814a66b4.png");
  background-position: 0 0;
  background-size: 24px 24px;
}

html, body {
  background-color: #fafafa;
}

@keyframes animate-out {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}

#cover {
  z-index: 1001;
  background-color: var(--rc-blue);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

#cover h1 {
  color: #fff;
  letter-spacing: -6px;
  font-size: 128px;
  font-weight: 140;
}

#cover.removing {
  opacity: 0;
  animation: 1s cubic-bezier(.64, 0, .78, 0) animate-out;
}

@keyframes background-animation {
  0% {
    background-color: var(--rc-blue);
  }

  16.6667% {
    background-color: var(--rc-pink);
  }

  33.3333% {
    background-color: var(--rc-orange);
  }

  50% {
    background-color: var(--rc-green);
  }

  66.6667% {
    background-color: var(--rc-violet);
  }

  83.3333% {
    background-color: var(--rc-red);
  }

  100% {
    background-color: var(--rc-blue);
  }
}

body.debug {
  background-color: gray;
  border-right: 1px solid #000;
}

.debug #background {
  background: #fafafa url("ResponsiveGuide.7937c358.png") repeat-y;
}

#background {
  width: 375px;
  min-height: 100vh;
  margin: 0;
  padding: 12px 0 0;
  position: relative;
  left: calc(50vw - 187.5px);
}

@media (width >= 500px) {
  #background {
    width: 500px;
    left: calc(50vw - 250px);
  }
}

@media (width >= 768px) {
  #background {
    width: 768px;
    left: calc(50vw - 384px);
  }
}

@media (width >= 1024px) {
  #background {
    width: 1024px;
    left: calc(50vw - 512px);
  }
}

@media (width >= 1280px) {
  #background {
    width: 1280px;
    left: calc(50vw - 640px);
  }
}

body h1, body h2, body h3, body h4, body h5, body div, body li, body p {
  color: #0075ff;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
  letter-spacing: .005rem;
  text-underline-offset: 3px;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

@media (width >= 500px) {
  body h1, body h2, body h3, body h4, body h5, body div, body li, body p {
    font-size: 14px;
    line-height: 20px;
  }
}

h3 {
  text-decoration: underline;
}

a:visited {
  color: #0075ff;
}

.weak {
  opacity: .6;
}

li {
  list-style: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

header h1, header h2, header li, header a, nav h1, nav h2, nav li, nav a {
  color: var(--rc-blue);
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 350;
  line-height: 18px;
}

@media (width >= 500px) {
  header h1, header h2, header li, header a, nav h1, nav h2, nav li, nav a {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  header h1 br.break-small, header h2 br.break-small, header li br.break-small, header a br.break-small, nav h1 br.break-small, nav h2 br.break-small, nav li br.break-small, nav a br.break-small {
    display: none;
  }
}

header ul, nav ul {
  margin: 0;
  line-height: 20px;
}

header {
  z-index: 100;
  margin: 14px 0 0 12px;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width >= 500px) {
  header {
    margin: 21px 0 0 22px;
  }
}

nav {
  z-index: 101;
  text-align: right;
  margin: 8px 12px;
  position: fixed;
  top: 0;
  right: 0;
}

@media (width >= 500px) {
  nav {
    margin: 18px 22px;
  }
}

nav ul.horizontal {
  line-height: 16px;
}

nav ul.horizontal li {
  padding-left: 6px;
  padding-right: 0;
}

section {
  margin-top: 87px;
}

section#contact ul {
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#connectors {
  opacity: 0;
  animation: 3s forwards fadeIn;
}

.info-box-container, .solo-box {
  pointer-events: none;
  height: 450px;
}

.debug .info-box, .debug .solo-box {
  border-top: 1px dotted #858585;
}

.info-box, .solo-box {
  opacity: 0;
  animation: 3s forwards fadeIn;
  position: relative;
}

.info-box img, .info-box video, .solo-box img, .solo-box video {
  z-index: 30;
  pointer-events: auto;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 4px #0003;
}

.info-box img.no-shadow, .info-box video.no-shadow, .solo-box img.no-shadow, .solo-box video.no-shadow {
  box-shadow: none;
}

.info-box .info-pointer, .solo-box .info-pointer {
  position: absolute;
  top: 105px;
  left: 175px;
}

.info-box .box-safari-fix, .solo-box .box-safari-fix {
  -webkit-backdrop-filter: blur(20px);
  box-sizing: border-box;
  border-radius: 7px;
}

.info-box .box, .solo-box .box {
  box-sizing: border-box;
  z-index: 40;
  pointer-events: auto;
  backdrop-filter: blur(20px);
  background-color: #fafafad9;
  border: 1px solid #0075ff;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 500px) {
  .info-box .box, .solo-box .box {
    border: 2px solid #0075ff;
    border-radius: 8px;
  }
}

.info-box .box h3, .info-box .box h4, .info-box .box p, .solo-box .box h3, .solo-box .box h4, .solo-box .box p {
  text-decoration: none;
}

.info-box .box .box-text-padded, .solo-box .box .box-text-padded {
  border-top-left-radius: 5px;
  padding: 2px 7px 1px 6px;
}

@media (width >= 500px) {
  .info-box .box .box-text-padded, .solo-box .box .box-text-padded {
    border-top-left-radius: 0;
    padding: 3px 12px 3px 7px;
  }
}

.info-box .box .box-text, .solo-box .box .box-text {
  padding: 5px 6px 7px;
}

@media (width >= 500px) {
  .info-box .box .box-text, .solo-box .box .box-text {
    padding: 5px 17px 9px 8px;
  }
}

.info-box .box .box-text-content, .solo-box .box .box-text-content {
  max-width: 600px;
}

.info-box .box .box-text-expand a, .solo-box .box .box-text-expand a {
  color: var(--rc-pink);
  text-decoration: none;
}

.info-box .box .box-header, .solo-box .box .box-header {
  border-bottom: 1px solid #0075ff;
  display: flex;
}

@media (width >= 500px) {
  .info-box .box .box-header, .solo-box .box .box-header {
    border-bottom: 2px solid #0075ff;
  }
}

.info-box .box .box-header div:first-child, .solo-box .box .box-header div:first-child {
  color: #fff;
  background-color: #0075ff;
}

.info-box .box .box-header div:first-child h3, .solo-box .box .box-header div:first-child h3 {
  color: #fff;
  font-weight: 400;
}

.info-box .pullout, .solo-box .pullout {
  width: 2px;
  height: 12px;
  position: absolute;
  top: -14px;
  left: 46px;
}

.info-box .pullout .pullout-content, .solo-box .pullout .pullout-content {
  white-space: nowrap;
  border-radius: 100px;
  height: 22px;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  top: -22px;
  left: -37px;
  overflow: visible;
}

@media (width >= 500px) {
  .info-box .pullout .pullout-content, .solo-box .pullout .pullout-content {
    height: 25px;
  }
}

section.text {
  max-width: 580px;
  margin: 22px 22px 40px;
}

section.text h3, section.text p {
  margin-bottom: 16px;
}

.section-header {
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.section-header h1 {
  letter-spacing: -8px;
  text-align: center;
  color: var(--rc-pink);
  border-radius: 8px;
  font-size: 120px;
  font-weight: 100;
  line-height: 120px;
  display: inline-block;
}

.border-blue {
  border: 1px solid var(--rc-blue);
}
/*# sourceMappingURL=index.681505b7.css.map */
